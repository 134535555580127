.ck {
	--ck-border-radius: 0;
	--ck-inner-shadow: none;

	/* Overrides the default font size in the theme. */
	--ck-font-size-base: #{$font-size-base};

	/* Helper variables to avoid duplication in the colors. */
	--ck-custom-background: var(--#{$prefix}component-bg);
	--ck-custom-foreground: var(--#{$prefix}component-hover-bg);
	--ck-custom-border: var(--#{$prefix}component-border-color);
	--ck-custom-white: var(--#{$prefix}component-color);

	/* -- Overrides generic colors. ------------------------------------------------------------- */
	--ck-color-base-border: var(--#{$prefix}component-border-color);
	--ck-color-base-background: var(--ck-custom-background);
	--ck-color-base-foreground: var(--ck-custom-background);
	--ck-color-focus-border: var(--#{$prefix}component-focus-border-color);
	--ck-color-text: var(--#{$prefix}component-color);
	--ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2);
	--ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1);

	/* -- Overrides the default .ck-button class colors. ---------------------------------------- */

	--ck-color-button-default-background: var(--ck-custom-background);
	--ck-color-button-default-hover-background: var(--#{$prefix}component-hover-bg);
	--ck-color-button-default-active-background: var(--#{$prefix}component-hover-bg);
	--ck-color-button-default-active-shadow: none;
	--ck-color-button-default-disabled-background: var(--ck-custom-background);

	--ck-color-button-on-color: var(--#{$prefix}component-hover-color);
	--ck-color-button-on-background: var(--#{$prefix}component-hover-bg);
	--ck-color-button-on-hover-background: var(--#{$prefix}component-hover-bg);
	--ck-color-button-on-active-background: var(--#{$prefix}component-hover-bg);
	--ck-color-button-on-active-shadow: none;
	--ck-color-button-on-disabled-background: var(--ck-custom-foreground);

	--ck-color-button-action-background: var(--#{$prefix}component-bg);
	--ck-color-button-action-hover-background: var(--#{$prefix}component-hover-bg);
	--ck-color-button-action-active-background: var(--#{$prefix}component-active-bg);
	--ck-color-button-action-active-shadow: none;
	--ck-color-button-action-disabled-background: none;
	--ck-color-button-action-text: var(--ck-custom-white);

	--ck-color-button-save: var(--#{$prefix}primary);
	--ck-color-button-cancel: var(--#{$prefix}gray-500);

	/* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

	--ck-color-dropdown-panel-background: var(--ck-custom-background);
	--ck-color-dropdown-panel-border: var(--ck-custom-foreground);

	/* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */

	--ck-color-split-button-hover-background: var(--ck-color-button-default-hover-background);
	--ck-color-split-button-hover-border: var(--ck-custom-foreground);

	/* -- Overrides the default .ck-input class colors. ----------------------------------------- */

	--ck-color-input-background: var(--ck-custom-background);
	--ck-color-input-border: hsl(257, 3%, 43%);
	--ck-color-input-text: hsl(0, 0%, 98%);
	--ck-color-input-disabled-background: hsl(255, 4%, 21%);
	--ck-color-input-disabled-border: hsl(250, 3%, 38%);
	--ck-color-input-disabled-text: hsl(0, 0%, 78%);

	/* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */

	--ck-color-labeled-field-label-background: var(--ck-custom-background);

	/* -- Overrides the default .ck-list class colors. ------------------------------------------ */

	--ck-color-list-background: var(--ck-custom-background);
	--ck-color-list-button-hover-background: var(--ck-custom-foreground);
	--ck-color-list-button-on-background: hsl(208, 88%, 52%);
	--ck-color-list-button-on-text: var(--ck-custom-white);

	/* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */

	--ck-color-panel-background: var(--ck-custom-background);
	--ck-color-panel-border: var(--ck-custom-border);

	/* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

	--ck-color-toolbar-background: var(--ck-custom-background);
	--ck-color-toolbar-border: var(--ck-custom-border);

	/* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

	--ck-color-tooltip-background: hsl(252, 7%, 14%);
	--ck-color-tooltip-text: hsl(0, 0%, 93%);

	/* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */

	--ck-color-image-caption-background: hsl(0, 0%, 97%);
	--ck-color-image-caption-text: hsl(0, 0%, 20%);

	/* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */

	--ck-color-widget-blurred-border: hsl(0, 0%, 87%);
	--ck-color-widget-hover-border: hsl(43, 100%, 68%);
	--ck-color-widget-editable-focus-background: var(--ck-custom-white);

	/* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */

	--ck-color-link-default: hsl(190, 100%, 75%);
	
	&.ck-toolbar {
		border: none !important;
		border-bottom: 1px solid var(--ck-custom-border) !important;
	}
	&.ck-editor__main {
		& .ck-content {
			border: none !important;
		}
	}
	&.ck-editor__editable_inline {
  	min-height: rem(200px);
  }
}