.btn-amarillo {
  background-color: #fcbf00 !important;

  color: white !important;
  border-color: #f2b700 !important;
}

.btn-amarillo:hover {
  background-color: #e3ac00 !important;
}

.btn-rojo {
  background-color: #dc2c27 !important;

  color: white !important;
}

.btn-rojo:hover {
  background-color: #dc2c12 !important;
}

.btn-azul {
  background-color: #41b1c3 !important;
  color: white !important;
  border-color: #41b1c3 !important;
}

.btn-azul:hover {
  background-color: #38a1b2 !important;
}

.btn-verde {
  background-color: #cbd623 !important;
  color: white !important;
}
.btn-verde:hover {
  background-color: #b7c120 !important;
}

.btn-rosa {
  background-color: #e5006b !important;
  color: white !important;
}
.btn-rosa:hover {
  background-color: #ce0060 !important;
}

.btn-naranja {
  background-color: #ed6c26 !important;
  color: white !important;
}
.btn-naranja:hover {
  background-color: #e45d13 !important;
}

.bg-amarillo {
  background-color: #fcbf00 !important;
}

.bg-azul {
  background-color: #41b1c3 !important;
}

.bg-rojo {
  background-color: #dc2c27 !important;
}

.bg-verde {
  background-color: #cbd623 !important;
}

.bg-rosa {
  background-color: #e5006b !important;
}

.bg-naranja {
  background-color: #ed6c26 !important;
}

.text-amarillo {
  color: #fcbf00 !important;
}

.text-azul {
  color: #41b1c3 !important;
}

.text-rojo {
  color: #dc2c27 !important;
}

.text-verde {
  color: #cbd623 !important;
}

.text-rosa {
  color: #e5006b !important;
}

.text-naranja {
  color: #ed6c26 !important;
}

.text-negro {
  color: #000000 !important;
}

.border-amarillo {
  border-color: #fcbf00 !important;
}

.border-azul {
  border-color: #41b1c3 !important;
}

.border-rojo {
  border-color: #dc2c27 !important;
}

.border-verde {
  border-color: #cbd623 !important;
}

.border-rosa {
  border-color: #e5006b !important;
}

.border-naranja {
  border-color: #ed6c26 !important;
}

.border-negro {
  border-color: #000000 !important;
}

table .enlace {
  text-decoration: none !important;
  color: #41b1c3 !important;
}

table .enlace:hover {
  color: #38a1b2 !important;
}

#agendaTab button {
  background-color: #41b1c3 !important;
  color: white !important;
  border-color: #41b1c3 !important;
}

#agendaTab button:hover {
  background-color: #38a1b2 !important;
}

#disponibilidadTab button {
  background-color: #41b1c3 !important;
  color: white !important;
  border-color: #41b1c3 !important;
}

#disponibilidadTab button:hover {
  background-color: #38a1b2 !important;
}

.nav-pills .nav-link.active {
  background-color: #e5006b !important;
}

.tables-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos tablas por fila */
  gap: 20px;
}

.table-container {
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .tables-grid {
    grid-template-columns: 1fr; /* Una tabla por fila en pantallas más pequeñas */
  }
}

.clamp-text {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limita a 3 líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fixed-table-1 {
  min-width: 100%;
  table-layout: fixed;
}

.fixed-table-1 thead th:nth-child(1),
.fixed-table-1 tbody td:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 100; /* Asegura que las columnas fijas estén por encima de las demás */
  background-color: white;
}

.table-auto-width {
  width: 100%; /* Permitir que la tabla use todo el ancho disponible */
  table-layout: auto; /* Ajustar el tamaño de las columnas basado en el contenido */
}

.table-auto-width th,
.table-auto-width td {
  padding: 8px; /* Añadir espacio alrededor del texto */
  white-space: nowrap; /* Prevenir que el texto se divida en varias líneas */
}

.table-auto-width th {
  text-align: left; /* Alinear texto de los encabezados a la izquierda */
}

.table-auto-width td {
  overflow: hidden; /* Ocultar cualquier desbordamiento de texto */
  text-overflow: ellipsis; /* Añadir puntos suspensivos si el texto es demasiado largo */
  white-space: nowrap; /* Evitar que el texto se envuelva */
}

.table-morado {
  background-color: #d45ffb6a;
}

.sticky-thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 99;
}
.sticky-thead th, .sticky-thead td {
  border: 1px solid #dee2e6
}

.sticky-column {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 100;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #dee2e6;
}

.table-sticky {
  max-height: 540px;
  overflow: auto;
}

.table {
  border-collapse: separate; 
  border-spacing: 0;
}
