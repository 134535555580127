body {
	& #gritter-notice-wrapper {
		width: rem(420px);
		z-index: $app-header-zindex + 10;
		right: $spacer * 1.33;
		top: $spacer * 1.33;
		
		@include media-breakpoint-down(sm) {
			width: auto;
			right: 0;
			top: 0;
			padding: $app-content-padding-y-sm * 0.75 $app-content-padding-x-sm;
			max-width: rem(360px);
		}
		
		& .gritter-item-wrapper {
			background: none;
			background: rgba(#{var(--#{$prefix}gray-900-rgb)}, .95);
			border-radius: $border-radius;
			box-shadow: $box-shadow-lg;
			
			.dark-mode & {
				background: rgba(#{var(--#{$prefix}gray-700-rgb)}, .95);
			}
			
			& .gritter-top,
			& .gritter-bottom {
				background: none;
				display: none;
			}
			& .gritter-item {
				font-family: inherit;
				padding: $spacer * 0.75 $spacer;
				background: none;
				display: flex;
				flex: 1;
				align-items: center;
				
				@if $enable-rtl {
					padding-left: rem(100px);
				} @else {
					padding-right: rem(100px);
				}
				@include media-breakpoint-down(sm) {
					padding: $spacer;
					
					@if $enable-rtl {
						padding-left: rem(80px);
					} @else {
						padding-right: rem(80px);
					}
				}
				
				& .gritter-with-image,
				& .gritter-without-image {
					float: none;
					width: auto;
					flex: 1;
				}
				& .gritter-image {
					margin: 0;
					height: auto;
					border-radius: $border-radius * 20;
					
					@if $enable-rtl {
						margin-left: $spacer;
					} @else {
						margin-right: $spacer;
					}
					@include media-breakpoint-down(sm) {
						width: rem(36px);
					}
				}
				& .gritter-title {
					font-size: $h5-font-size;
					line-height: $headings-line-height;
					padding: 0;
					margin-bottom: rem(2px);
					font-weight: $font-weight-bold;
					color: $white;
					text-shadow: none;
					display: block;
					
					@include media-breakpoint-down(sm) {
						font-size: $font-size-lg;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis; 
					}
				}
				& p {
					font-size: $font-size-sm;
					line-height: $line-height-base;
					color: $gray-500;
					
					@include media-breakpoint-down(sm) {
						max-height: ($font-size-sm * $line-height-base * 2);
						overflow: hidden;
					}
				}
				& .gritter-close {
					left: auto;
					right: 0;
					top: 0;
					bottom: 0;
					background: none;
					width: rem(85px);
					height: auto;
					display: block !important;
					font-family: inherit;
					color: $gray-500;
					
					@if $enable-rtl {
						right: auto;
						left: 0;
						border-right: 1px solid rgba($white, .1);
					} @else {
						border-left: 1px solid rgba($white, .1);
					}
					@include media-breakpoint-down(sm) {
						width: rem(65px);
					}
					
					&:before {
						content: 'Close';
						position: absolute;
						text-align: center;
						top: 0;
						right: 0;
						left: 0;
						bottom: 0;
						color: inherit;
						z-index: 10;
						text-indent: 0;
						font-family: inherit;
						font-size: $font-size-base;
						font-weight: $font-weight-bold;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					&:hover,
					&:focus {
						color: $white;
						outline: none;
					}
				}
			}
			
			&.gritter-light {
				background: rgba($white, .98);
				
				& .gritter-item,
				& .gritter-bottom,
				& .gritter-top {
					color: $gray-600;
				}
				& .gritter-item {
					& .gritter-title {
						color: $gray-900;
					}
					& p {
						color: $gray-700;
					}
				}
				& .gritter-close {
					border-color: $gray-300;
					color: lighten($dark, 30%);
					
					&:hover,
					&:focus {
						&:before {
							color: $gray-900;
						}
					}
				}
			}
			&.without-rounded-img {
				& .gritter-item {
					& .gritter-image {
						border-radius: 0;
					}
				}
			}
		}
	}
}