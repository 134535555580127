@media print {
    .print_btn {
        display: none;
    }
}

.blue {
    background-color: blue;
}

.loader {
    border: 16px solid #E5006B;
    /* Light grey */
    border-top: 16px solid #CBD623;
    /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}