.mailbox {
	height: 100%;
	border-top: 1px solid var(--#{$prefix}component-border-color);
	background: var(--#{$prefix}light);
	display: flex;
	
	@include media-breakpoint-down(lg) {
		flex-direction: column;
	}
	@include media-breakpoint-down(md) {
		min-height: calc(100vh - #{$app-header-height * 2 + rem(1px)});
	}
	
	& .mailbox-sidebar {
		@include media-breakpoint-up(lg) {
			width: rem(200px);
			display: flex;
			flex-direction: column;
			flex: 0 0 200px;
		}
		& .mailbox-sidebar-header {
			padding: $spacer;
			border-bottom: 1px solid var(--#{$prefix}component-border-color);
		}
		& .mailbox-sidebar-content {
			flex: 1;
			
			@include media-breakpoint-up(lg) {
				overflow: hidden;
			}
			@include media-breakpoint-down(lg) {
				border-bottom: 1px solid var(--#{$prefix}component-border-color);
			}
		}
	}
	& .mailbox-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		
		@include media-breakpoint-up(lg) {
			max-width: calc(100% - 200px);
			
			@if $enable-rtl {
				border-right: 1px solid var(--#{$prefix}component-border-color);
			} @else {
				border-left: 1px solid var(--#{$prefix}component-border-color);
			}
		}
		
		& .mailbox-content-header {
			padding: $spacer;
			border-bottom: 1px solid var(--#{$prefix}component-border-color);
		}
		& .mailbox-content-body {
			background: var(--#{$prefix}component-bg);
			flex: 1;
			
			@include media-breakpoint-up(md) {
				overflow: hidden;
			}
			
			& .mailbox-float-link {
				padding: $spacer * .5 $spacer;
				display: flex;
	
				& a {
					color: rgba(var(--#{$prefix}component-color-rgb), .5);
					text-decoration: none;
					font-weight: $font-weight-bold;
		
					&:hover,
					&:focus {
						color: rgba(var(--#{$prefix}component-color-rgb), 1);
					}
				}
			}
			& .mailbox-to {
				position: relative;
				border-bottom: 1px solid var(--#{$prefix}component-border-color);
				padding: $spacer * 0.555 $spacer;
				min-height: rem(46px);
				display: flex;
				align-items: flex-start;
	
				& .tagit {
					border-color: transparent !important;
					border-radius: 0;
					flex: 1;
				}
				& .form-control {
					border-color: transparent !important;
					padding-left: $spacer !important;
					padding-right: $spacer !important;
					border-radius: 0;
		
					&:focus {
						box-shadow: none;
					}
				}
				& .control-label {
					width: rem(60px);
					padding: $spacer * .5 $spacer;
					margin-bottom: 0;
					font-weight: $form-label-font-weight;
					background: $blue-100;
					color: $blue-600;
					text-align: center;
					min-height: $input-height;
				}
			}
			& .mailbox-subject {
				padding: $spacer * 0.555 0;
				border-bottom: 1px solid var(--#{$prefix}component-border-color);
	
				& .form-control {
					border-color: transparent !important;
					padding-left: $spacer !important;
					padding-right: $spacer !important;
					border-radius: 0;
		
					&:focus {
						box-shadow: none;
					}
				}
			}
			& .mailbox-form {	
				min-height: 100%;
				display: flex;
				flex-direction: column;
			}
			& .mailbox-input {
				padding: 0;
				margin: 0;
				flex: 1;
				display: flex;
				flex-direction: column;
		
				& .wysihtml5-toolbar {
					background: var(--#{$prefix}component-bg) !important;
					border: none !important;
					padding-bottom: 0 !important;
				}
				& .ql-snow.ql-toolbar,
				& .note-editor .note-toolbar {
					background: var(--#{$prefix}light) !important;
					border-color: var(--#{$prefix}component-border-color) !important;
				}
				& .note-editor {
					height: 100%;
					min-height: rem(100px);
					display: flex;
					flex-direction: column;
					flex: 1;
					
					& .note-toolbar {
						padding: rem(6px) $spacer !important;
					
						& .note-btn-group {
							margin: 0 !important;
						}
						& .note-btn {
							background: none;
							border: none;
							color: var(--#{$prefix}component-color);
						
							&:hover,
							&.active {
								background: #{rgba(var(--#{$prefix}component-bg-rgb), .75)};
								border-radius: $border-radius !important;
								box-shadow: none !important;
							}
						}
					}
					& .note-editing-area {
						display: flex;
						flex-direction: column;
						flex: 1;
						
						& .note-editable {
							height: 100% !important;
							flex: 1;
						}
					}
				}
				
				& .ql-container {
					display: flex;
					flex-direction: column;
				}
				& .ql-editor,
				& .ql-container,
				& .wysihtml5-sandbox {
					flex: 1;
				}
				& .wysihtml5-sandbox {
					padding: $spacer !important;
					border: none !important;
					outline: none !important;
					border-radius: 0 !important;
				}
			}
		}
		& .mailbox-content-footer {
			padding: $spacer;
			border-top: 1px solid var(--#{$prefix}component-border-color);
		}
	}
	& .nav-title {
		font-size: rem(11px);
		color: rgba(var(--#{$prefix}component-color-rgb), .5);
		padding: rem(15px) rem(15px) rem(7px);
	}
	& .nav-inbox {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: block;
		
		& > li {
			display: block;
			
			& + li {
				margin-top: 0;
			}
			& > a {
				text-decoration: none;
				border-radius: 0;
				padding: $spacer * .5 $spacer;
				color: var(--#{$prefix}component-color);
				font-weight: $font-weight-bold;
				display: flex;
				align-items: center;
				
				& i {
					width: rem(24px);
				}
				&:hover {
					color: rgba(var(--#{$prefix}component-color-rgb), .5);
				}
			}
			&.active {
				& > a {
					background-color: var(--#{$prefix}component-border-color);
					color: var(--#{$prefix}component-color);
				}
			}
		}
	}
	& .attached-document {
		list-style-type: none;
		margin: rem(15px) 0 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		
		& > li {
			width: rem(180px);
			background: var(--#{$prefix}component-bg);
			color: var(--#{$prefix}component-color);
			font-weight: 600;
			position: relative;
			margin-right: rem(15px);
			margin-bottom: rem(15px);
			border: 1px solid var(--#{$prefix}component-border-color);
			
			@if $enable-rtl {
				margin-right: 0;
				margin-left: rem(15px);
			}
			
			&:before {
				position: absolute;
				top: rem(-1px);
				right: rem(-1px);
				font-size: rem(12px);
				background: var(--#{$prefix}light);
				width: rem(20px);
				height: rem(20px);
				line-height: rem(20px);
				text-align: center;
				
				@include fontawesome();
			}
			& img {
				max-width: 100%;
			}
			& .document-name {
				padding: rem(5px) rem(10px);
				text-align: center;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				background: var(--#{$prefix}light);
				border-top: 1px solid var(--#{$prefix}component-border-color);
				
				& a {
					color: var(--#{$prefix}component-color);
				}
			}
			& .document-file {
				height: rem(70px);
				background: none;
				overflow: hidden;
				text-align: center;
				line-height: rem(70px);
				font-size: rem(32px);
				margin: rem(-1px);
			}
		}
	}
	& .list-email {
		border-top: 1px solid var(--#{$prefix}component-border-color);
		border-bottom: 1px solid var(--#{$prefix}component-border-color);
		margin: -1px 0;
		padding: 0;
		
		& > li {
			&.list-group-item {
				border: none;
				margin-bottom: 0;
				padding: rem(15px);
				position: relative;
				width: 100%;
				overflow: hidden;
				border-radius: 0;
				display: flex;
				align-items: center;
				
				@include clearfix();
				
				& + li {
					&.list-group-item {
						border-top: 1px solid #{rgba(var(--#{$prefix}component-border-color-rgb), .75)};
					}
				}
				
				& a {
					color: var(--#{$prefix}component-color);
					text-decoration: none;
				}
				& .email-info {
					max-width: calc(100% - 46px);
					flex: 1;
					
					& > a {
						margin-top: rem(-15px);
						margin-bottom: rem(-15px);
						padding: rem(15px);
						display: flex;
						flex-wrap: wrap;
						
						@if $enable-rtl {
							margin-left: rem(-15px);
						} @else {
							margin-right: rem(-15px);
						}
						@include media-breakpoint-down(sm) {
							display: block;
						}
					}
				}
				& .email-user {
					width: rem(30px);
					height: rem(30px);
					min-width: rem(30px);
					overflow: hidden;
					font-size: rem(18px);
					line-height: rem(30px);
					text-align: center;
					color: $white;
					background: $gray-500;
					margin: rem(-5px) 0;
					border-radius: 30px;
					
					& img {
						max-width: 100%;
						display: block;
					}
				}
				& .email-sender {
					display: block;
					width: rem(200px);
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					
					@include media-breakpoint-down(lg) {
						width: rem(160px);
					}
					@include media-breakpoint-down(md) {
						width: rem(120px);
					}
					@include media-breakpoint-down(sm) {
						width: auto;
						font-size: rem(14px);
						padding-right: rem(90px);
						
						@if $enable-rtl {
							padding-right: rem(15px);
							padding-left: rem(90px);
						}
					}
				}
				& .email-title {
					display: block;
					line-height: rem(20px);
					width: rem(300px);
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					
					@include media-breakpoint-up(xl) {
						@if $enable-rtl {
							padding-right: rem(15px);
						} @else {
							padding-left: rem(15px);
						}
					}
					@include media-breakpoint-down(xl) {
						width: auto;
						flex: 1;
					}
					
					& + .email-desc {
						@include media-breakpoint-up(md) {
							padding-left: rem(20px);
							
							@if $enable-rtl {
								padding-left: 0;
								padding-right: rem(20px);
							}
							&:before {
								content: '';
								position: absolute;
								left: rem(6px);
								top: rem(8px);
								width: rem(4px);
								height: rem(4px);
								background: rgba(var(--#{$prefix}component-color-rgb), .25);
								border-radius: $border-radius-lg;
								
								@if $enable-rtl {
									left: auto;
									right: rem(6px);
								}
							}
						}
					}
				}
				& .email-desc {
					display: block;
					position: relative;
					color: rgba(var(--#{$prefix}component-color-rgb), .5);
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					flex: 1;
					
					@include media-breakpoint-down(xl) {
						display: none;
					}
					@include media-breakpoint-down(sm) {
						display: block;
						padding-right: 0;
					}
				}
				& .email-time {
					display: block;
					font-size: rem(11px);
					font-weight: $font-weight-bold;
					color: rgba(var(--#{$prefix}component-color-rgb), .65);
					width: rem(100px);
					right: 0;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					
					@if $enable-rtl {
						text-align: left;
					} @else {
						text-align: right;
					}
					@include media-breakpoint-down(lg) {
						width: rem(90px);
						top: 0;
						position: absolute;
						padding-top: 15px;
						
						@if $enable-rtl {
							padding-left: 15px;
						} @else {
							padding-right: 15px;
						}
					}
				}
				& .email-checkbox {
					& .custom-checkbox {
						width: rem(16px);
						padding-left: rem(16px);
						
						@if $enable-rtl {
							padding-left: 0;
							padding-right: rem(16px);
						}
						
						& .custom-control-label {
							&::before,
							&::after {
								left: rem(-16px);
								
								@if $enable-rtl {
									left: auto;
									right: rem(-16px);
								}
							}
						}
					}
					& + .email-user {
						margin-left: rem(10px);
						
						@if $enable-rtl {
							margin-left: 0;
							margin-right: rem(10px);
						}
					} 
				}
				
				&.unread {
					& .email-title,
					& .email-sender {
						font-weight: $font-weight-bold;
					}
				}
				&.selected {
					background: rgba($yellow-200, .25);
				}
			}
		}
	}
}